import React, { Suspense, lazy, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ConfigProvider, Layout, Spin } from "antd";
import { notNull } from "./sharedFunctions.js";
import ErrorBoundary from "./ErrorBoundary.js";

const Heading = lazy(() => import("./components/Header.js"));
const Footer = lazy(() => import("./components/Footer.js"));
const CartDrawer = lazy(() => import("./components/CartDrawer.js"));

const { Content } = Layout;

function App() {
  const [showHeader, setShowHeader] = useState(false);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const content = useSelector((state) => state.content?.contentData);
  const venue = useSelector((state) => state.venue?.info?.venue);
  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    if (location.pathname === "/" && notNull(content)) {
      setShowHeader(false);
      setWaiting(false);
    } else {
      setShowHeader(true);
      setWaiting(false);
    }
    document.querySelector("link[rel='icon']").href = content?.menu?.favicon?.url;
    if (notNull(venue)) {
      document.title = `${venue?.name} | ${content?.menu?.mode} | ${venue?.address}`;
    }
  },[location.pathname, content, venue])

  return (
    <div
    style={{
      '--dynamic-color': content?.menu?.primary_color || '#295c53',
      '--secondary-color': content?.menu?.secondary_color || '#f6ffed',
      '--header-color': content?.components?.filter((c) => c.type === "heading")[0]?.heading_color || '#295c53',
      '--headetext-color': content?.components?.filter((c) => c.type === "heading")[0]?.text_color || '#fff',
    }}
    >
      <ErrorBoundary>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary:content?.menu?.primary_color || '#295c53',
            colorSuccess: content?.menu?.secondary_color || '#f6ffed',
            colorBgLayout: `#fff`,
            borderRadius: 7,
          },
          components:{
            Image: {
              zIndexPopup: 1401,
              zIndexPopupBase: 1400,
            },
          }
        }}
      >
        <Layout>
          <Spin spinning={waiting} size="large" fullscreen style={{zIndex: 1400}}/>
          <Content>
            <Suspense fallback={<div>Loading...</div>}>
              {showHeader && <Heading />}
              <CartDrawer open={open} setOpen={setOpen} />
              <Outlet />
              <Footer />
            </Suspense>
          </Content>
        </Layout>
      </ConfigProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
